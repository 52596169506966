import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_upd_agent_roll",
  ref: "updRollAgentModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  class: "btn btn-icon btn-sm btn-active-icon-primary",
  id: "kt_upd_agent_close",
  "data-bs-dismiss": "modal"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_9 = { class: "fv-row mb-9" }
const _hoisted_10 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }
const _hoisted_13 = { class: "modal-footer flex-center" }
const _hoisted_14 = {
  "data-bs-dismiss": "modal",
  type: "reset",
  class: "btn btn-light me-3"
}
const _hoisted_15 = {
  type: "submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_16 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(` ${_ctx.agent.name} (${_ctx.agent.code}) ` + _ctx.$t("Agents.Edit")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createVNode(_component_Form, {
          class: "form w-100",
          onSubmit: _ctx.onUpdateAgent,
          "validation-schema": _ctx.rules
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("Agents.RollingRate")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "roll_pct",
                  type: "number"
                }),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_ErrorMessage, { name: "roll_pct" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", _hoisted_14, _toDisplayString(_ctx.$t("Cancel")), 1),
              _createElementVNode("button", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("Agents.Edit")), 1)
              ], 512)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"])
      ])
    ])
  ], 512))
}