
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import moment from "moment";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import NewSubAgent from "./NewSubAgent.vue";
import UpdateRollRate from "./UpdateRollRate.vue";
import UpdateSubAgent from "./UpdateSubAgent.vue";
import SubAgentTree from "./SubAgentTree.vue";

interface IAgent {
  agent_count: string;
  name: string;
  code: string;
  al_balance: number;
  al_points: number;
  betamount: number;
  id: string;
  level: number;
  roll_pct: number;
  status: number;
  today_betamount: number;
  today_winamount: number;
  upper_agent: string;
  winamount: number;
}
interface DateRange {
  start: string;
  end: string;
}

export default defineComponent({
  name: "agents",
  components: {
    Datatable,
    DateRangePicker,
    NewSubAgent,
    SubAgentTree,
    UpdateRollRate,
    UpdateSubAgent,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    const tableHeader = ref([
      {
        name: t("Agents.Name"),
        key: "name",
        customslot: true,
      },
      {
        name: t("Agents.Level"),
        key: "level",
        customslot: true,
      },
      {
        name: t("Agents.State"),
        key: "status",
        customslot: true,
      },
      {
        name: t("Agents.AlWallet"),
        key: "al_balance",
        customslot: true,
      },
      {
        name: t("Agents.Rolling"),
        key: "rolling",
        sortable: true,
        customslot: true,
      },
      {
        name: t("Agents.Winamount"),
        key: "winamount",
        sortable: true,
        customslot: true,
      },
      {
        name: t("Agents.Profit"),
        key: "profit",
        sortable: true,
        customslot: true,
      },
      {
        name: t("Agents.RollingProfit"),
        key: "al_points",
        sortable: true,
        currency: true,
      },
      {
        name: t("Agents.FinalProfit"),
        key: "finalprofit",
        sortable: true,
        customslot: true,
      },
      {
        name: t("Agents.RollPercent"),
        key: "roll_pct",
        customslot: true,
      },
      {
        name: t("Agents.Action"),
        key: "action",
        customslot: true,
      },
    ]);

    const tableData = ref<Array<IAgent>>([]);
    const initAgents = ref<Array<IAgent>>([]);
    const tempAgentSearch = ref("");
    const tempLevelSearch = ref(2);
    /**
     * Filters
     *
     */
    let daterange = {
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };
    const setSelectedDate = (date: DateRange) => (daterange = date);
    const search = ref({
      name: "",
      code: "",
    });
    /**
     * Get SubAgents
     *
     */
    const getSubAgents = async (searchId = "", searchLevel = 2) => {
      tempAgentSearch.value = searchId;
      tempLevelSearch.value = searchLevel;
      const results = await ApiService.post(
        `/agent/subagents?start=${daterange.start}&end=${daterange.end}`,
        {
          searchId: tempAgentSearch.value,
          level: tempLevelSearch.value,
        }
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
      initAgents.value.splice(0, results.length, ...results);
    };

    const getComputedToday = (betamount: number, today_betamount: number) =>
      Number(betamount) + Number(today_betamount);

    /**
     * Set Agent Data
     *
     */
    const selectedAgent = ref({});
    const setAgent = (agent: IAgent) => (selectedAgent.value = agent);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.AgentList"), [t("Menu.AgentManage")]);
      getSubAgents();
    });

    return {
      tableData,
      tableHeader,
      getSubAgents,
      search,
      setSelectedDate,
      getComputedToday,
      setAgent,
      selectedAgent,
      tempAgentSearch,
      tempLevelSearch,
    };
  },
});
