import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_date_picker, {
        modelValue: _ctx.daterange,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.daterange = $event)),
        type: "daterange",
        "unlink-panels": "",
        "range-separator": "~",
        "start-placeholder": _ctx.$t('DateRange.Start'),
        "end-placeholder": _ctx.$t('DateRange.End'),
        shortcuts: _ctx.shortcuts,
        change: _ctx.emitSelectedDates()
      }, null, 8, ["modelValue", "start-placeholder", "end-placeholder", "shortcuts", "change"])
    ]),
    (_ctx.showshortcuts)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-danger",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setDate('this_month')))
            }, _toDisplayString(_ctx.$t("DateRange.ThisMonth")), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-success",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setDate('last_month')))
            }, _toDisplayString(_ctx.$t("DateRange.LastMonth")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setDate('this_week')))
            }, _toDisplayString(_ctx.$t("DateRange.ThisWeek")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn btn-warning",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setDate('last_week')))
            }, _toDisplayString(_ctx.$t("DateRange.LastWeek")), 1)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}