import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_upd_agent",
  ref: "updAgentModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  class: "btn btn-icon btn-sm btn-active-icon-primary",
  id: "kt_upd_agent_close",
  "data-bs-dismiss": "modal"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_9 = { class: "fv-row mb-9" }
const _hoisted_10 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }
const _hoisted_13 = { class: "fv-row mb-9" }
const _hoisted_14 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = { class: "fv-row mb-9" }
const _hoisted_18 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_19 = { class: "fv-plugins-message-container" }
const _hoisted_20 = { class: "fv-help-block" }
const _hoisted_21 = { class: "fv-row mb-9" }
const _hoisted_22 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_23 = { class: "fv-plugins-message-container" }
const _hoisted_24 = { class: "fv-help-block" }
const _hoisted_25 = { class: "fv-row mb-9" }
const _hoisted_26 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_27 = { class: "fv-plugins-message-container" }
const _hoisted_28 = { class: "fv-help-block" }
const _hoisted_29 = { class: "fv-row mb-9" }
const _hoisted_30 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_31 = { class: "fv-plugins-message-container" }
const _hoisted_32 = { class: "fv-help-block" }
const _hoisted_33 = { class: "fv-row mb-9" }
const _hoisted_34 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_35 = { class: "fv-plugins-message-container" }
const _hoisted_36 = { class: "fv-help-block" }
const _hoisted_37 = { class: "fv-row mb-9" }
const _hoisted_38 = { class: "fs-6 fw-bold required mb-2" }
const _hoisted_39 = { class: "fv-plugins-message-container" }
const _hoisted_40 = { class: "fv-help-block" }
const _hoisted_41 = { class: "modal-footer flex-center" }
const _hoisted_42 = {
  "data-bs-dismiss": "modal",
  type: "reset",
  class: "btn btn-light me-3"
}
const _hoisted_43 = {
  type: "submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_44 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(` ${_ctx.agent.name} (${_ctx.agent.code}) ` + _ctx.$t("Agents.Edit")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createVNode(_component_Form, {
          class: "form w-100",
          onSubmit: _ctx.onUpdateAgent,
          "validation-schema": _ctx.rules
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("Agents.Name")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "name",
                  type: "text",
                  modelValue: _ctx.agentObj.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.agentObj.name = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_ErrorMessage, { name: "name" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("Agents.Password")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "password",
                  type: "password",
                  autocomplete: "on"
                }),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_ErrorMessage, { name: "password" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("Agents.IpAddress")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "ipaddress",
                  type: "text",
                  modelValue: _ctx.agentObj.ipaddress,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.agentObj.ipaddress = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_ErrorMessage, { name: "ipaddress" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("Agents.Domain")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "domain",
                  type: "text",
                  modelValue: _ctx.agentObj.domain,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.agentObj.domain = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_ErrorMessage, { name: "domain" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("Agents.SeamlessURLBalance")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "url_balance",
                  type: "text",
                  modelValue: _ctx.agentObj.url_balance,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.agentObj.url_balance = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createVNode(_component_ErrorMessage, { name: "url_balance" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t("Agents.SeamlessURLDebit")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "url_debit",
                  type: "text",
                  modelValue: _ctx.agentObj.url_debit,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.agentObj.url_debit = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_ErrorMessage, { name: "url_debit" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("Agents.SeamlessURLCredit")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "url_credit",
                  type: "text",
                  modelValue: _ctx.agentObj.url_credit,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.agentObj.url_credit = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _createVNode(_component_ErrorMessage, { name: "url_credit" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t("Agents.SeamlessURLRollback")), 1),
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  name: "url_rollback",
                  type: "text",
                  modelValue: _ctx.agentObj.url_rollback,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.agentObj.url_rollback = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    _createVNode(_component_ErrorMessage, { name: "url_rollback" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("button", _hoisted_42, _toDisplayString(_ctx.$t("Cancel")), 1),
              _createElementVNode("button", _hoisted_43, [
                _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t("Agents.Edit")), 1)
              ], 512)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"])
      ])
    ])
  ], 512))
}