
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import moment from "moment";

export default defineComponent({
  name: "DateRangePicker",
  emits: ["changedate"],
  props: {
    initial: { type: String },
    showshortcuts: { type: Boolean },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const daterange = ref([new Date(), new Date()]);

    if (props.initial) {
      if (props.initial === "month") {
        daterange.value = [
          moment().startOf("month").toDate(),
          moment().toDate(),
        ];
      }
    }

    const shortcuts = ref([
      {
        text: t("DateRange.Today"),
        value: (() => {
          const end = new Date();
          const start = new Date();
          return [start, end];
        })(),
      },
      {
        text: t("DateRange.Yesterday"),
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
          return [start, end];
        })(),
      },
      {
        text: t("DateRange.LastWeek"),
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        })(),
      },
    ]);
    const emitSelectedDates = () => {
      const selectedDates: any = daterange.value;
      const start = moment(selectedDates[0]).format("YYYY-MM-DD");
      const end = moment(selectedDates[1]).format("YYYY-MM-DD");
      emit("changedate", { start, end });
    };

    const setDate = (range: string) => {
      if (range === "this_week")
        daterange.value = [moment().subtract(7, "days").toDate(), new Date()];
      else if (range === "this_month")
        daterange.value = [moment().startOf("month").toDate(), new Date()];
      else if (range === "last_week")
        daterange.value = [
          moment().subtract(14, "days").toDate(),
          moment().subtract(7, "days").toDate(),
        ];
      else if (range === "last_month")
        daterange.value = [
          moment().subtract(1, "months").startOf("month").toDate(),
          moment().startOf("month").subtract(1, "days").toDate(),
        ];
    };

    return {
      daterange,
      shortcuts,
      emitSelectedDates,
      setDate,
    };
  },
});
