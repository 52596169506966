
import { defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "updateAgent",
  emits: ["refresh"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    agent: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();

    //Create form validation object
    const rules = Yup.object().shape({
      name: Yup.string().required(),
      password: Yup.string().min(6),
      ipaddress: Yup.string().required(),
      domain: Yup.string().required(),
      url_balance: Yup.string().required(),
      url_debit: Yup.string().required(),
      url_credit: Yup.string().required(),
      url_rollback: Yup.string().required(),
    });

    const agentObj = ref({
      id: "",
      name: "",
      domain: "",
      ipaddress: "",
      url_balance: "",
      url_debit: "",
      url_credit: "",
      url_rollback: "",
    });

    watch(
      () => props.agent,
      () => {
        agentObj.value.id = props.agent.id;
        agentObj.value.name = props.agent.name;
        agentObj.value.domain = props.agent.domain;
        agentObj.value.ipaddress = props.agent.ipAddress;
        agentObj.value.url_balance = props.agent.url_balance;
        agentObj.value.url_debit = props.agent.url_debit;
        agentObj.value.url_credit = props.agent.url_credit;
        agentObj.value.url_rollback = props.agent.url_rollback;
      }
    );

    const onUpdateAgent = async (values: any) => {
      await ApiService.patch(`/agent/subagents/${props.agent.id}`, values)
        .then(() => {
          Swal.fire({
            text: t("Agents.Edit"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("Agents.EditAgentSuccess"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          const close = document.getElementById("kt_add_agent_close");
          if (close) close.click();
          emit("refresh");
        })
        .catch(() => {
          Swal.fire({
            text: t("Agents.EditAgentFailed"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("TryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    return {
      rules,
      onUpdateAgent,
      agentObj,
    };
  },
});
