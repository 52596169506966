
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n/index";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "update-agent",
  emits: ["refresh"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    agent: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    //Create form validation object
    const rules = Yup.object().shape({
      roll_pct: Yup.number().required(),
    });

    const onUpdateAgent = async (values: any) => {
      await ApiService.post(`/agent/subagent/roll`, {
        upper_agent: props.agent.id,
        roll_pct: Number(values.roll_pct),
      })
        .then(() => {
          Swal.fire({
            text: t("Agents.Edit"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("Agents.EditSuccess"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          const close = document.getElementById("kt_add_agent_close");
          if (close) close.click();
          emit("refresh");
        })
        .catch(() => {
          Swal.fire({
            text: t("Agents.Edit"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("TryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    return {
      rules,
      onUpdateAgent,
    };
  },
});
