<template>
  <div
    class="modal fade"
    id="kt_modal_sub_agent"
    ref="newSubAgentModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <Form
          class="form w-100"
          @submit="onAddSubAgent"
          :validation-schema="rules"
        >
          <div class="modal-header">
            <h2 class="fw-bolder">{{ $t("Agents.NewSubAgent") }}</h2>
            <div
              class="btn btn-icon btn-sm btn-active-icon-primary"
              id="kt_sub_agent_close"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!-- Agent Name -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.SubAgent")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="name"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" />
                </div>
              </div>
            </div>
            <!-- Agent Password -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.Password")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="password"
                type="password"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="password" />
                </div>
              </div>
            </div>
            <!-- IP Address -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.IpAddress")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="ipaddress"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="ipaddress" />
                </div>
              </div>
            </div>
            <!-- Domain -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.Domain")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="domain"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="domain" />
                </div>
              </div>
            </div>
            <!-- Rolling % -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.RollingRate")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="roll_pct"
                type="number"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="roll_pct" />
                </div>
              </div>
            </div>
            <!-- Upper Agent -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.UpperAgent")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="upper_agent"
                as="select"
              >
                <option
                  v-for="item in subAgents"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ `${item.name} (${item.code})` }}
                </option>
              </Field>
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="upper_agent" />
                </div>
              </div>
            </div>
            <!-- URL Balance -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.SeamlessURLBalance")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="url_balance"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="url_balance" />
                </div>
              </div>
            </div>
            <!-- URL Debit -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.SeamlessURLDebit")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="url_debit"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="url_debit" />
                </div>
              </div>
            </div>
            <!-- URL Credit -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.SeamlessURLCredit")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="url_credit"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="url_credit" />
                </div>
              </div>
            </div>
            <!-- URL Rollback -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Agents.SeamlessURLRollback")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="url_rollback"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="url_rollback" />
                </div>
              </div>
            </div>
          </div>
          <!--end::Modal body-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              data-bs-dismiss="modal"
              type="reset"
              id="kt_sub_agent_cancel"
              class="btn btn-light me-3"
            >
              {{ $t("Cancel") }}
            </button>
            <!--end::Button-->
            <!-- Submit -->
            <button
              type="submit"
              ref="submitButton"
              id="kt_sign_in_submit"
              class="btn btn-primary"
            >
              <span class="indicator-label">{{
                $t("Agents.NewSubAgent")
              }}</span>

              <span class="indicator-progress">
                {{ $t("Loading") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
        </Form>
        <!--end::Form-->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n/index";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "new-sub-agent",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  emits: ["refresh"],
  setup(props, { emit }) {
    const { t } = useI18n();

    //Create form validation object
    const rules = Yup.object().shape({
      name: Yup.string().required(),
      password: Yup.string().min(6).required(),
      ipaddress: Yup.string().required(),
      domain: Yup.string().required(),
      url_balance: Yup.string().required(),
      url_debit: Yup.string().required(),
      url_credit: Yup.string().required(),
      url_rollback: Yup.string().required(),
      roll_pct: Yup.number().required(),
      upper_agent: Yup.string().uuid().required(),
    });

    /*
     * Add Sub Agent
     *
     */
    const onAddSubAgent = async (values) => {
      await ApiService.post("/agent/subagent", {
        name: values.name.trim(),
        password: values.password.trim(),
        ipaddress: values.ipaddress.trim(),
        domain: values.domain.trim(),
        url_balance: values.url_balance.trim(),
        url_debit: values.url_debit.trim(),
        url_credit: values.url_credit.trim(),
        url_rollback: values.url_rollback.trim(),
        roll_pct: values.roll_pct,
        upper_agent: values.upper_agent,
      })
        .then(() => {
          Swal.fire({
            text: t("Agents.NewSubAgent"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("Agents.NewSubAgentSuccess"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          document.getElementById("kt_sub_agent_close").click();
          emit("refresh");
        })
        .catch(() => {
          Swal.fire({
            text: t("Agents.NewSubAgentFailed"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("TryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    /*
     * Sub Agent List
     *
     */
    const subAgents = ref([]);
    const getSubAgents = async () => {
      subAgents.value = await ApiService.get("/agent/subagent/list")
        .then((res) => res.data)
        .catch(() => []);
    };

    onMounted(() => getSubAgents());

    return {
      rules,
      onAddSubAgent,
      subAgents,
    };
  },
});
</script>
