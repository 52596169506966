<template>
  <div class="card">
    <div class="card-body pt-0">
      <Tree
        class="pt-6"
        :nodes="data"
        :search-text="searchText"
        :use-checkbox="false"
        :use-icon="true"
        show-child-count
        @nodeExpanded="onNodeExpanded"
        @nodeClick="onNodeClick"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import Tree from "vue3-tree";
import "vue3-tree/dist/style.css";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: {
    Tree,
  },
  emits: ["getSubAgents"],
  setup(props, { emit }) {
    const data = ref([{}]);
    const searchText = ref("");
    const onNodeExpanded = (node, state) => {
      console.log("state: ", state);
      console.log("node: ", node);
    };

    const onNodeClick = (node) => {
      const id = node.id;
      const level = Number(node.level) + 1;
      emit("getSubAgents", id, level);
    };

    /**
     * Get SubAgents Tree
     *
     */
    const getSubAgentsTree = async () => {
      const results = await ApiService.get(`/agent/subagents/tree`)
        .then((res) => res.data)
        .catch(() => []);
      data.value = nestItems(results);
    };

    const nestItems = (items, id = null, link = "upper_agent") =>
      items
        .filter((item) => item[link] === id)
        .map((item) => ({ ...item, nodes: nestItems(items, item.id) }));

    onMounted(() => {
      getSubAgentsTree();
    });

    return {
      data,
      searchText,
      onNodeExpanded,
      onNodeClick,
    };
  },
});
</script>
